import React from "react"
import { graphql} from "gatsby"
import HomePage from "../components/HomePage/HomePage"
import "../styles/reset.css"
import SEO from "../components/SEO/SEO"
const Index = ({ data }) => {
	if(!data) return null
	return (
		<>
			<SEO />
			<HomePage/>
		</>
	)
}

export default Index

export const query = graphql`
	query MyQuery {
		site {
			siteMetadata {
				title
				description
			}
		}
		productData: allMarkdownRemark(
			sort: { fields: frontmatter___priority, order: ASC }
			filter: { frontmatter: { templateKey: { eq: "Product" } } }
		) {
			edges {
				node {
					frontmatter {
						title
						intro
						priority
						content {
							caption
							image {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
				}
			}
		}
		allMarkdownRemark(filter: { frontmatter: { title: { eq: "About" } } }) {
			edges {
				node {
					frontmatter {
						aboutText
						image
					}
				}
			}
		}
	}
`