import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import arrow2 from "../../../static/fonts/Arrow2.svg";
import * as styles from "./HomePage.module.scss";
import ReactMarkdown from "react-markdown";

const HomePage = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { title: { eq: "HomePage" } } }) {
        edges {
          node {
            frontmatter {
              content {
                type
                windowText
                fullText
                perfumeHasOverlay
                perfumeLink
                buttonLabel
                fullImage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (!data) return null;
  const content = data.allMarkdownRemark.edges[0].node.frontmatter.content;

  return (
    <section>
      {content.map((section) => {
        switch (section.type) {
          case "fullImage":
            return (
              <div className={styles.doubleImageWrap} key={getSrc(section.fullImage)}>
                <img className={styles.fullImage} src={getSrc(section.fullImage)} alt="" />
              </div>
            );
          case "image":
            return (
              <div className={styles.singleImageWrap} key={getSrc(section.image)}>
                <img src={getSrc(section.image)} alt="" />
                {section.perfumeHasOverlay && (
                  <Link to={`/products/${section.perfumeLink.toLowerCase()}`}>
                    <div className={styles.shopLinkBtn}>
                      <span>Shop {section.buttonLabel}</span>
                      <img className={styles.arrow} src={arrow2} alt="Arrow" />
                    </div>
                  </Link>
                )}
              </div>
            );
          case "windowText":
            return <ReactMarkdown key={section.windowText} className={styles.windowText}>{section.windowText}</ReactMarkdown>;
          case "fullText":
            return <ReactMarkdown key={section.fullText} className={styles.fullText}>{section.fullText}</ReactMarkdown>;
          default:
            return <p key={section.type}>Data type not recognized</p>;
        }
      })}
    </section>
  );
};

export default HomePage;
