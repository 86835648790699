import React from "react"
import { Helmet } from "react-helmet"

const defaultMeta = [
	{
		name: "author",
		content: `In'n'out Fragrances`,
	},
	{
		name: "keywords",
		content:
			"Fragrance Store, Innout fragrances, Niklaus Mettler, Parfume, Almoist, Parfumist",
	},
	{ name: "msapplication-config", content: "/favicons/browserconfig.xml" },
	{ name: "theme-color", content: "#ffffff" },
	{ name: "msapplication-TileColor", content: "#ffffff" },
]

const SEO = () => {
	return (
		<Helmet
			htmlAttributes={{ lang: "en" }}
			title={`In'n'out Fragrances`}
			meta={[
				...defaultMeta,
				{
					name: "description",
					content:
						"Founded in 2016 by Niklaus Mettler, In’n’out Fragrances is a house for alternative perfumery based in Amsterdam. In addition to commissioned fragrances, In’n’out produces self-initiated projects in collaboration with artists from fields outside of traditional perfumery. Past collaborations have addressed topics such as fashion, urbanism, sexuality, design, language, life, and death.",
				},

				// OG
				{ property: "og:title", content: "In'n'out Fragrances" },
				{ property: "og:site_name", content: "Random Studio" },
				{
					property: "og:description",
					content:
						"Founded in 2016 by Niklaus Mettler, In’n’out Fragrances is a house for alternative perfumery based in Amsterdam. In addition to commissioned fragrances, In’n’out produces self-initiated projects in collaboration with artists from fields outside of traditional perfumery. Past collaborations have addressed topics such as fashion, urbanism, sexuality, design, language, life, and death.",
				},
				{ property: "og:type", content: "website" },
				{ property: "og:locale", content: "en_US" },
				{ property: "og:url", content: "https://innoutfragrances.com" },
				{
					property: "og:image",
					content: "https://innoutfragrances.com/innoutSVG_OG.png",
				},

				// Explicit image sizing for twitter
				{ property: "og:image:width", content: 800 },
				{ property: "og:image:height", content: 800 },

				// Twitter
				{ name: "twitter:title", content: `In'n'out Fragrances` },
				{
					name: "twitter:description",
					content:
						"Founded in 2016 by Niklaus Mettler, In’n’out Fragrances is a house for alternative perfumery based in Amsterdam. In addition to commissioned fragrances, In’n’out produces self-initiated projects in collaboration with artists from fields outside of traditional perfumery. Past collaborations have addressed topics such as fashion, urbanism, sexuality, design, language, life, and death.",
				},
				{
					name: "twitter:image",
					content: "https://innoutfragrances.com/innoutSVG_OG.png",
				},
				{ name: "twitter:card", content: "summary_large_image" },
				{ name: "twitter:url", content: "https://innoutfragrances.com" },
			]}
		>
			<meta
				name="viewport"
				content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
			></meta>
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="/favicons/apple-touch-icon.png"
			/>
			<link
				rel="apple-touch-icon-precomposed"
				href="/favicons/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href="/favicons/favicon-32x32.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href="/favicons/favicon-16x16.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="192x192"
				href="/favicons/android-chrome-192x192.png"
			/>
			<link
				rel="mask-icon"
				href="/favicons/safari-pinned-tab.svg"
				color="#0000ff"
			/>
			<link rel="shortcut icon" href="/favicons/favicon.ico" />
		</Helmet>
	)
}

export default SEO
